// @ts-strict-ignore
/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";
/**
 * A hook wrapping useNavigate from Remix and react-router-dom
 * It can be used interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 *
 * @returns - either a Remix or react-router-dom based navigate function
 */
const useNavigate = () => {
  let routerNavigate: router.NavigateFunction;
  let remixNavigate: remix.NavigateFunction;
  let routerError: Error;
  let remixError: Error;

  // Order is important here, we default to trying the react-router-dom version
  // If it does not throw, we know we are operating in some sort of nested router during the Remix migration
  // Otherwise if it fails, we know we are on a Remix only page
  try {
    routerNavigate = router.useNavigate();
  } catch (e) {
    routerError = e;
  }

  try {
    remixNavigate = remix.useNavigate();
  } catch (e) {
    // Ignore must be used in a <Router> component as we handle that by also invoking the equivalent Router API
    // Additional check for navigate in case the react-router-dom useNavigate and the Remix useNavigate both fail
    if (!routerNavigate) {
      remixError = e;
    }
  }

  if (routerError && remixError) {
    throw routerError;
  }

  return routerNavigate || remixNavigate;
};

export { useNavigate };
